// import i18next from "i18next";
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import GoogleTranslate from "../../GoogleTranslate";

import { useCookies } from "react-cookie";
import Select from "react-select";
import { getLanguage, getTheme } from "../../util/axiosConfig/api/api";
import { ENV } from "./../../util/Env";
import "./layout.css";
const Header = () => {
  const [cookies, setCookie] = useCookies(["googtrans"]);
  // const { t } = useTranslation(["general"]);
  const [logo, setlogo] = useState("");
  // const [i18changeLanguage, setI18ChangeLanguage] = useState("")
  const navigate = useNavigate();
  const handleRegisterType = (tabName) => {
    navigate(`${ENV.URL_APP}/beneficiary-app`);
  };
  const [supportedLanguage, setSupportedLanguage] = useState([]);
  const [options, setOptions] = useState([]);
  const [lang, setLang] = useState({});
  const [gLang, setGLang] = useState("");
  useEffect(() => {
    getUserThemelog();
  }, []);
  useEffect(() => {
    // i18next.changeLanguage(i18changeLanguage);
  }, []);
  useEffect(() => {
    getUserThemelog();
    let str = cookies.googtrans;
    console.log("STR", str);
    if (str != undefined) {
      let parts = str.split("/");
      let lastPart = parts[parts.length - 1];
      localStorage.setItem("Glang", lastPart);
      fetchLanguage(lastPart);
    } else {
      fetchLanguage("en");
    }
  }, [gLang]);
  const getUserThemelog = () => {
    const response = getTheme();

    response.then(
      (success) => {
        setlogo(success.logo);
        document.title = success.title; // Quick solution
        localStorage.setItem("shortLogo", success.shortLogo);
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = success.titleLogo;
        const root = document.documentElement;
        if(success.colours){
          console.log(success.colours.primary_color, "colours console")
        root.style.setProperty(
          "--primary-color",
          success.colours.primary_color === undefined
            ? "27, 96, 152"
            : success.colours.primary_color
        );
        root.style.setProperty(
          "--footer-bg-color",
          success.colours.footer_bg_color === undefined
            ? "27, 96, 152"
            : success.colours.footer_bg_color
        );
        root.style.setProperty(
          "--footer-color",
          success.colours.footer_color === undefined
            ? "255, 255, 255"
            : success.colours.footer_color
        );
        root.style.setProperty(
          "--header-color",
          success.colours.header_color === undefined
            ? "255, 255, 255"
            : success.colours.header_color
        );
        root.style.setProperty(
          "--btn-bg-color",
          success.colours.btn_bg_color === undefined
            ? "27, 96, 152"
            : success.colours.btn_bg_color
        );
        root.style.setProperty(
          "--btn-text-color",
          success.colours.btn_text_color === undefined
            ? "255, 255, 255"
            : success.colours.btn_text_color
        );
      }
      },
      (error) => {}
    );
  };

  const changeLanguage = (value) => {
    let str;
    if (value != null) {
      localStorage.setItem("supportedWGLanguage", value.value);
      setLang({ label: value.localLanguage, value: value.value });
      // removeCookie("googtrans",{domain: '.finlabsindia.org', path:'/'})
      // Cookies.remove('googtrans', { path: '', domain: '.yourdomain.com' })

      document.cookie = "googtrans" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.finlabsindia.org";

      setCookie("googtrans", `/en/${value.value.toLowerCase()}`, {
        path: "/",
      });
      window.location.reload();
    } else {
      str = null;
      setLang({});
    }
  };

  const fetchLanguage = (lastPart) => {
    const resp = getLanguage();
    resp.then(
      (success) => {
        setOptions([]);
        Object.values(
          success.map((item) => {
            if (item.enable == true) {
              supportedLanguage.push(item.id);
              console.log("lastPart", lastPart);

              if (lastPart == item.id.toLowerCase()) {
                // document.cookie =
                // "googtrans=; path=/; domain=.finlabsindia.org;expires=Thu, 01 Jan 1970 00:00:01 GMT";

                document.cookie = "googtrans" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.finlabsindia.org";

                setCookie("googtrans", `/en/${item.id.toLowerCase()}`, {
                  path: "/",
                });
                localStorage.setItem("supportedWGLanguage", item.id);
                setLang({ label: item.localLanguage, value: item.id });
              }
              let singleItem = { value: item.id, label: item.localLanguage };
              setOptions((prevData) => [...prevData, singleItem]);
              localStorage.setItem("supportedWTLanguage", supportedLanguage);
            }
          })
        );
      },
      (error) => {}
    );
  };

  return (
    <header className="header">
      <div className="container-lg">
        <div className="top_bar">
          <div className="brand_logo">
            <Link to="/">
              {" "}
              <img src={logo} className="brand_logo_img" alt="Brand Logo"></img>
            </Link>
          </div>
          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-4 col-sm-4 lang">
            <Select
              className="Selectontrol notranslate"
              value={lang}
              onChange={changeLanguage}
              options={options}
            />
            </div>
          <div className="login_signup">
            <GoogleTranslate />
           
            <div className="login_signup_btn_area">
              <a
                className="login_btn"
                href={`${ENV.API_KEY_URL_BEN}/beneficiary-app`}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Login{" "}
              </a>
              {/* <Link to="/createAccount"><button className="signup_btn">Sign Up</button></Link> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
