
export const uploadedDocs =[
    {label:"Male",value:102002},
    {label:"Female",value:102001},

                              
]
export const uspData = [
    {
        "id": 53,
        "name": "Index Fund",
        "place": "Nation Icon MS Road",
        "state": "Maharashtra",
        "city": "Pune",
        "pincode": "413248",
        "mapLink": "https://www.google.com/maps/place/Mohol,+Maharashtra/@17.8086039,75.5977061,13z/data=!3m1!4b1!4m6!3m5!1s0x3bc4340d708e64ed:0xcf894159dea77b7!8m2!3d17.8091987!4d75.6382422!16s%2Fm%2F05t0zqg?entry=ttu",
        "trainerName": "Pramod Trainer",
        "substituteTrainerName": null,
        "offline": true,
        "rechedule": false,
        "startTime": "2024-05-28 17:00:00",
        "endTime": "2024-05-28 18:00:00",
        "activeForRegistration": true,
        "qrCode": null,
        "language": "Bengali",
        "eventTopicName": null,
        "meetingLink": null,
        "linkGenerated": null,
        "cancelled": false,
        "maxParticipants": 10,
        "status": "Pending",
        "feedback": null,
        "regCount": 9,
        "attendance": 0
    },
       
];
export const uspTemData = 
    {
        id: 1,
        orgname: "AMFI",
        name: "Introduction to Mutual Funds",
        place: "Kolkata",
        state: "West Bengal",
        city: "Kolkata",
        pincode: "700103",
        mapLink: "na",
        trainerName: "Trupti Raut",
        substituteTrainerName: "Trupti Raut",
        offline: false,
        rechedule: false,
        startTime: "2024-05-01 15:00:00",
        endTime: "2024-05-01 17:00:00",
    }
