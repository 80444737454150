import { Box } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import both_icon from "../../template/images/both_icon.svg";
import delf_icon from "../../template/images/delf_icon.svg";
import InputElement from "../../util/InputElement";
import SelectElement from "../../util/SelectElement";
import { ValidationPattern } from "../../util/ValidationPattern";
import {
    fetchSingleEvent,
    registerEXUser,
    registerUser,
} from "../../util/axiosConfig/api/api";
import { uploadedDocs } from "../../util/master";
import Loader from "../../util/preloader/Preloader";
// import { uspTemData } from './../../util/master';
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { downloadQr } from "../../services/ApiCaller/Registration";
import { ENV } from "../../util/Env";
import Eventscard from "../home/Eventscard";
import QRScanner from "../layout/QRScanner";

const Registration = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [mandatory, setMandatory] = useState(true);
  const [uspTemData, setuspTemData] = useState([]);
  const [selectedDateerrors, setselectedDateerrors] = useState(false);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const [valuedayjs, setValuedayjs] = useState(null);
  const [selectedTab, setSelectedTab] = useState("NewRegistered");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const [show, setShow] = useState(false);

  const [triggerModal, setTriggerModal] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  setTimeout(() => {
    setLoading(false);
  }, 500);
  const handleEventType = (tabName) => {
    setSelectedTab(tabName);
    // handleEventDetails(tabName);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  } = useForm();

  const useFromProps = {
    register,
    errors,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  };

  const [openQr, setOpenQr] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const resposnse = fetchSingleEvent(id);
    resposnse.then(
      (success) => {
        setLoading(false);
        
        setuspTemData(success);
      },
      (error) => {
        setLoading(false);

        swal({
          text: !error.data.message
            ? "We are temporarily experiencing technical difficulties. Please try again after sometime "
            : error.data.message,
          icon: "error",
          button: "OK",
        }).then((value) => {
          if (value === true) {
            navigate(`/`);
          }
        });
      }
    );
  }, []);

  const handlemodal = () => {
    setShowScannerModal(true);
    setOpenQr(!openQr);
  };
  const handleClose = () => {
    setOpenQr(!openQr);
    setShowScannerModal(false);
    // localStorage.removeItem("HTML5_QRCODE_DATA")
  };
  // const handleScanner = () => {
  //   // localStorage.removeItem("HTML5_QRCODE_DATA")
  //   setShowScannerModal(true)
  // }
  const formatsToSupport = [
    Html5QrcodeSupportedFormats.QR_CODE,
    Html5QrcodeSupportedFormats.UPC_A,
    Html5QrcodeSupportedFormats.UPC_E,
    Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
  ];
  // const [decodedResults, setDecodedResults] = useState([]);
  // let isWaitingForResponse = false;
  // const onNewScanResult = (decodedText, decodedResult) => {

  //   if (isWaitingForResponse) {
  //     console.log("Still waiting for the previous scan...");
  //     return;
  //   }

  //   isWaitingForResponse = true;

  //   console.log(`Message: ${decodedText}, Result: ${JSON.stringify(decodedResult)}`);
  //   setTimeout(() => {
  //     setShowScannerModal(false)
  //     localStorage.removeItem("HTML5_QRCODE_DATA")
  //   }, 500);

  //   const Data = JSON.stringify({
  //     "qr": decodedText,
  //   });

  //   // Set a timeout to allow the next scan
  //   setTimeout(() => {
  //     isWaitingForResponse = false;
  //   }, 5000); // 5000ms = 5 seconds, adjust as needed
  //   const resp = downloadQr(Data);
  //   resp.then(
  //     (success) => {

  //       if (!success) {
  //         swal({
  //           title: "try",

  //           icon: "error",
  //           backdrop: false,
  //           allowOutsideClick: false,
  //           showCloseButton: true,
  //           allowOutsideClick: true,
  //           showCloseButton: true,
  //           closeOnClickOutside: false,
  //           showCancelButton: true,
  //           focusConfirm: true,

  //         });
  //       } if (success) {
  //         swal({
  //           title: "Scan Successful",

  //           icon: "success",
  //           backdrop: false,
  //           allowOutsideClick: false,
  //           showCloseButton: true,
  //           allowOutsideClick: true,
  //           showCloseButton: true,
  //           closeOnClickOutside: false,
  //           showCancelButton: true,
  //           focusConfirm: true,

  //         });
  //         setValue("ParticipantName", success.name )
  //         setName(success.name)
  //         setValue("Mobile", success.mobile )
  //         setMobile(success.mobile)
  //         setValue("Email", success.email )
  //         setEmail(success.email)
  //         setValue("Gender", success.gender)
  //         setValue("Pincode", success.address)
  //         setDob(success.dob)
  //         setValuedayjs(success.dob)
  //         setValue("selectedDate", success.dob)
  //         setGender(success.gender)
  //         setValue("Gender", success.gender === "M" ? "Male" : "Female" )

  //       }
  //     },
  //     (error) => {
  //       console.log("error", error);
  //       swal({
  //         title: error.data?.message ? error.data?.message : "Please ensure upload the correct QR code, for authenticity",
  //         // content: error.data.message,
  //         // html: true,
  //         icon: "error",
  //         backdrop: false,
  //         allowOutsideClick: false,
  //         showCloseButton: true,
  //         allowOutsideClick: true,
  //         showCloseButton: true,
  //         closeOnClickOutside: false,
  //         showCancelButton: true,
  //         focusConfirm: true,

  //       })
  //     }
  //   );
  // };

  // const qrCodeErrorCallback = (errorMessage) => {
  //   console.log(errorMessage);
  //   document.getElementById("html5qr-code-full-region__header_message").innerHTML = "";
  //   document.getElementById("html5qr-code-full-region__header_message").innerHTML = "File should be in QR format "
  // };

  let divClassName = " col-12 ";
  let divClassNameSelectElement = " col-6 ";
  // const onSubmit = (data) => {
  //   setLoading(true);
  //   var nameArray = data.ParticipantName.split(/\s+/);
  //   const Data = JSON.stringify({
  //     firstName: nameArray[0],
  //     lastName:
  //       nameArray.length > 2
  //         ? nameArray[2]
  //         : nameArray.length > 1
  //         ? nameArray[1]
  //         : "",
  //     middleName: nameArray.length > 2 ? nameArray[1] : "",
  //     studentEmail: data.Email,
  //     studentMobileNumber: data.Mobile,
  //     selfRegistered: true,
  //     studentDOB: valuedayjs,
  //     pincode: data.PINcode,
  //     gender:
  //       gender === ""
  //         ? data.Gender.value
  //         : gender === "M"
  //         ? uploadedDocs[0].value
  //         : uploadedDocs[1].value,
  //     // gender: data.Gender.value
  //   });
  //   console.log("data to be submitted", data);

  //   const resposnse = registerUser(id, Data);

  //   resposnse.then(
  //     (success) => {
  //       swal({
  //         text: `You have been successfully registered for the event.Check your Email & WhatsApp for details`,
  //         icon: "success",
  //         button: "OK",
  //       }).then((value) => {
  //         if (value === true) {
  //           navigate(`/`);
  //         }

  //       });

  //       if (success) {
  //         setLoading(false);
  //       }
  //     },
  //     (error) => {
  //       setLoading(false);
  //       swal({
  //         text: !error.data.message
  //           ? "We are temporarily experiencing technical difficulties. Please try again after sometime "
  //           : error.data.message,
  //         icon: "error",
  //         button: "OK",
  //       }).then((value) => {
  //         // if (value === true) {
  //         //   navigate(`/`)
  //         // }
  //       });
  //     }
  //   );
  // };
  const onSubmit = async (data) => {
    setLoading(true);
    var nameArray = data.ParticipantName.split(/\s+/);
    const Data = JSON.stringify({
      firstName: nameArray[0],
      lastName:
        nameArray.length > 2
          ? nameArray[2]
          : nameArray.length > 1
          ? nameArray[1]
          : "",
      middleName: nameArray.length > 2 ? nameArray[1] : "",
      studentEmail: data.Email,
      studentMobileNumber: data.Mobile,
      selfRegistered: true,
      studentDOB: valuedayjs,
      pincode: data.PINcode,
      gender:
        gender === ""
          ? data.Gender.value
          : gender === "M"
          ? uploadedDocs[0].value
          : uploadedDocs[1].value,
      // gender: data.Gender.value
    });
setTimeout(() => {
  setLoading(true);
}, 5000);
    try {
      setConfirmationMsg(true);
      const fileRes = await registerUser(id, Data);
      setConfirmationMsg(true);
      swal({
        text: `You have been successfully registered for the event.Check your Email & WhatsApp for details`,
        icon: "success",
        button: "OK",
      }).then((value) => {
        if (value === true) {
          navigate(`/`);
        }
      });

      if (fileRes) {
        setLoading(false);
        setConfirmationMsg(false);
      }
    } catch (error) {
      setLoading(false);
      setConfirmationMsg(false);
      swal({
        text: !error.data.message
          ? "We are temporarily experiencing technical difficulties. Please try again after sometime "
          : error.data.message,
        icon: "error",
        button: "OK",
      }).then((value) => {
        // if (value === true) {
        //   navigate(`/`)
        // }
      });
    }
  };
  const regonSubmit = (data) => {
    // window.open(`${ENV.API_KEY_URL_BEN}/beneficiary-app`,'_blank', )

    // navigate()
    //alert("ss")
    // var nameArray = data.ParticipantName.split(/\s+/);
    const Data = JSON.stringify({
      studentEmail: data.RegEmail,
    });
    // console.log("data to be submitted", data);

    const resposnse = registerEXUser(id, Data);
    setLoading(true);
    resposnse.then(
      (success) => {
        swal({
          text: `You have been successfully registered for the event.Check your Email & WhatsApp for details`,
          icon: "success",
          button: "OK",
        }).then((value) => {
          if (value === true) {
            const url = `${ENV.API_KEY_URL_BEN}/beneficiary-app`;
            window.open(url, "_blank");
          }
        });

        if (success) {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        swal({
          text: !error.data.message
            ? "We are temporarily experiencing technical difficulties. Please try again after sometime "
            : error.data.message,
          icon: "error",
          button: "OK",
        }).then((value) => {
          // if (value === true) {
          //   navigate(`/`)
          // }
        });
      }
    );
  };

  const daterval = (data, x, y, type, max, min) => {
    let temdate = false;
    if (data === null) {
      if (data == "Invalid Date" || data === null) {
        temdate = true;
      } else {
        temdate = true;
      }
    } else {
      if (data.$d == "Invalid Date") {
        temdate = true;
      } else {
        if (
          max === "maxDate" &&
          min === "minDate"
          //only minDate & maxDate
        ) {
          if (
            moment(data.$d).diff(x, "days") < 0 ||
            moment(data.$d).diff(y, "days") > 0
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        } else if (
          min === "minDate" //only minDate
        ) {
          if (
            moment().subtract(24, "hours").diff(data.$d) <= 0 ||
            moment().diff(moment(data.$d), "years", true) > 99
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        } else if (
          max === "maxDate" //only maxDate
        ) {
          if (
            moment().add(15, "days").diff(data.$d) > 0 ||
            moment(data.$d).diff(moment(), "years", true) > 99
          ) {
            temdate = true;
          } else {
            temdate = false;
          }
        }
      }
    }
    switch (type) {
      case "DOB":
        setselectedDateerrors(temdate);
        break;

      default:
        break;
    }
  };
  const qrCodeSuccessCallback = (decodedText) => {
    const Data = JSON.stringify({
      qr: decodedText,
    });

    const resp = downloadQr(Data);
    resp.then(
      (success) => {
        if (!success) {
          swal({
            title: "try",

            icon: "error",
            backdrop: false,
            allowOutsideClick: false,
            showCloseButton: true,
            allowOutsideClick: true,
            showCloseButton: true,
            closeOnClickOutside: false,
            showCancelButton: true,
            focusConfirm: true,
          });
        }
        if (success) {
          swal({
            title: "Scan Successful",

            icon: "success",
            backdrop: false,
            allowOutsideClick: false,
            showCloseButton: true,
            allowOutsideClick: true,
            showCloseButton: true,
            closeOnClickOutside: false,
            showCancelButton: true,
            focusConfirm: true,
          });
          setValue("ParticipantName", success.name);
          setName(success.name);
          setValue("Mobile", success.mobile);
          setMobile(success.mobile);
          setValue("Email", success.email);
          setEmail(success.email);
          setValue("Gender", success.gender);
          setValue("Pincode", success.address);
          setDob(success.dob);
          setValuedayjs(success.dob);
          setValue("selectedDate", success.dob);
          setGender(success.gender);
          setValue("Gender", success.gender === "M" ? "Male" : "Female");
        }
      },
      (error) => {
        console.log("error", error);
        swal({
          title: error.data?.message
            ? error.data?.message
            : "Please ensure upload the correct QR code, for authenticity",
          // content: error.data.message,
          // html: true,
          icon: "error",
          backdrop: false,
          allowOutsideClick: false,
          showCloseButton: true,
          allowOutsideClick: true,
          showCloseButton: true,
          closeOnClickOutside: false,
          showCancelButton: true,
          focusConfirm: true,
        });
      }
    );
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="usp_main">
            <div className="container-lg my-5">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-around m-auto">
                  <Eventscard storeData={uspTemData} page={"reg"} />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 text-center Reg_div mb-5">
                    Registration
                  </div>

                  <div className="event_type_btn mb-5">
                    <button
                      type="button"
                      className={
                        selectedTab == "NewRegistered"
                          ? "Register_button_both w-50"
                          : "event_type_tab w-50"
                      }
                      onClick={() => handleEventType("NewRegistered")}
                    >
                      {" "}
                      {selectedTab == "NewRegistered" ? (
                        <img src={delf_icon} />
                      ) : (
                        <img src={both_icon} />
                      )}{" "}
                      New User{" "}
                    </button>
                    <button
                      type="button"
                      className={
                        selectedTab == "AlreadyRegistered"
                          ? "Register_button_both reg_border w-50"
                          : "event_type_tab reg_border w-50"
                      }
                      onClick={() => handleEventType("AlreadyRegistered")}
                    >
                      {" "}
                      {selectedTab == "AlreadyRegistered" ? (
                        <img src={delf_icon} />
                      ) : (
                        <img src={both_icon} />
                      )}{" "}
                      Existing User{" "}
                    </button>
                  </div>

                  {selectedTab == "AlreadyRegistered" ? (
                    <>
                      {" "}
                      <div className="row">
                        <InputElement
                          {...useFromProps}
                          divClassName={divClassName}
                          useForm={useForm}
                          readOnly={false}
                          type="text"
                          labelName="Registered Email"
                          sublabelName=""
                          pattern={{
                            value: ValidationPattern.email,
                            message: "Please enter valid Email",
                          }}
                          registerName={"RegEmail"}
                          name={"RegEmail"}
                          mandatory={mandatory}
                          onPaste={false}
                          onCopy={false}
                          onChange={(event) => {}}
                          errorLabel="Please enter valid Email"
                        />
                      </div>
                      <button
                        className="w-100 text-center Reg_Button mt-3"
                        onClick={handleSubmit(regonSubmit)}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <>
                      <div>
                        <button
                          className="w-100 text-center Reg_Button "
                          onClick={() => handlemodal()}
                        >
                          {openQr ? "Close" : "Open"} QR Scanner
                        </button>
                      </div>
                      <div className="w-100 text-center mt-3">
                        <b>OR</b>
                      </div>

                      <div className="row mt-3">
                        <InputElement
                          {...useFromProps}
                          divClassName={divClassName}
                          useForm={useForm}
                          readOnly={name === "" ? false : true}
                          type="text"
                          labelName="Participant Name"
                          sublabelName=""
                          pattern={{
                            value: ValidationPattern.name,
                            message: "Please enter valid name",
                          }}
                          registerName={"ParticipantName"}
                          name={"ParticipantName"}
                          mandatory={mandatory}
                          onPaste={false}
                          onCopy={false}
                          onChange={(event) => {}}
                          errorLabel="Please enter valid name"
                        />
                        <InputElement
                          {...useFromProps}
                          divClassName={divClassName}
                          useForm={useForm}
                          readOnly={email === "" ? false : true}
                          type="text"
                          labelName="Email"
                          sublabelName=""
                          pattern={{
                            value: ValidationPattern.email,
                            message: "Please enter valid Email",
                          }}
                          registerName={"Email"}
                          name={"Email"}
                          mandatory={mandatory}
                          onPaste={false}
                          onCopy={false}
                          onChange={(event) => {}}
                          errorLabel="Please enter valid Email"
                        />

                        <InputElement
                          {...useFromProps}
                          divClassName={divClassName}
                          useForm={useForm}
                          readOnly={mobile === "" ? false : true}
                          type="text"
                          labelName="Mobile"
                          sublabelName=""
                          pattern={{
                            value: ValidationPattern.mobile,
                            message: "Please enter valid Mobile",
                          }}
                          registerName={"Mobile"}
                          name={"Mobile"}
                          mandatory={mandatory}
                          onPaste={false}
                          onCopy={false}
                          onChange={(event) => {}}
                          errorLabel="Please enter valid Mobile"
                        />

                        <div className=" col-12 date-input-field">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <DesktopDatePicker
                                    displayStaticWrapperAs="desktop"
                                    clearable
                                    inputFormat="DD-MM-YYYY"
                                    disableFuture
                                    openTo="year"
                                    onChange={(newValue) => {
                                      daterval(
                                        newValue,
                                        moment()
                                          .subtract(99, "year", 1, "days")
                                          .calendar(),
                                        moment()
                                          .subtract(6575, "days")
                                          .calendar(),
                                        "DOB",
                                        "maxDate",
                                        "minDate"
                                      );
                                      onChange(newValue);
                                      setValuedayjs(newValue);
                                      setValue(
                                        name,
                                        newValue
                                          ? moment(newValue).format(
                                              "DD-MM-YYYY"
                                            )
                                          : newValue
                                      );
                                    }}
                                    minDate={moment()
                                      .subtract(25550, "days")
                                      .calendar()}
                                    maxDate={moment()
                                      .subtract(6575, "days")
                                      .calendar()}
                                    value={valuedayjs}
                                    label="Helper text example"
                                    renderInput={({
                                      inputRef,
                                      inputProps,
                                      InputProps,
                                    }) => (
                                      <Box>
                                        <div className="form-floating formgroup col-12">
                                          <input
                                            {...inputProps}
                                            inputRef={ref}
                                            ref={inputRef}
                                            type="text"
                                            name={name}
                                            disabled={dob === "" ? false : true}
                                            className={
                                              errors.selectedDate ||
                                              selectedDateerrors
                                                ? "form-control  formcontrol_ipa errorsborder "
                                                : "form-control  formcontrol_ipa "
                                            }
                                            id="first-name"
                                            placeholder="Date of Birth"
                                          />
                                          <label
                                            htmlFor="firstName"
                                            className="label-heading"
                                          >
                                            Date of Birth
                                            <span className="errormark">
                                              {" "}
                                              *
                                            </span>
                                          </label>

                                          {InputProps?.endAdornment}
                                        </div>
                                      </Box>
                                    )}
                                  />
                                );
                              }}
                              {...register("selectedDate", {
                                required: "Please enter valid Date of Birth",
                              })}
                              name="selectedDate"
                            />
                          </LocalizationProvider>
                          {(!errors?.selectedDate?.ref?.value ||
                            selectedDateerrors) && (
                            <small className="textdanger textdangerpadd">
                              {selectedDateerrors
                                ? "Please enter valid Date of Birth "
                                : errors?.selectedDate?.message}
                              {/* {errors?.selectedDate?.message} */}
                            </small>
                          )}
                        </div>
                        <InputElement
                          {...useFromProps}
                          divClassName={divClassNameSelectElement}
                          useForm={useForm}
                          readOnly={false}
                          type="text"
                          labelName="Pincode"
                          sublabelName=""
                          pattern={{
                            value: ValidationPattern.pin,
                            message: "Please enter valid PIN code",
                          }}
                          registerName={"PINcode"}
                          name={"PINcode"}
                          mandatory={mandatory}
                          onPaste={false}
                          onCopy={false}
                          onChange={(event) => {}}
                          errorLabel="Please enter valid  PIN code."
                        />

                        {gender === "" ? (
                          <>
                            <SelectElement
                              {...useFromProps}
                              clearable
                              divClassName={divClassNameSelectElement}
                              readOnly={false}
                              registerName={"Gender"}
                              mandatory={true}
                              labelName={"Gender"}
                              options={uploadedDocs}
                              onSelect={(value) => {}}
                              errorLabel="Please Select valid Gender"
                            />
                          </>
                        ) : (
                          <>
                            <InputElement
                              {...useFromProps}
                              divClassName={divClassNameSelectElement}
                              useForm={useForm}
                              readOnly={true}
                              type="text"
                              labelName="Gender"
                              sublabelName=""
                              mandatory={true}
                              registerName={"Gender"}
                              name={"Gender"}
                              onChange={(event) => {}}
                              errorLabel="Please Select valid Gender"
                            />
                          </>
                        )}
                      </div>

                      <button
                        className={!confirmationMsg ? "w-100 text-center Reg_Button mt-3" : "w-100 text-center Reg_Button mt-3 disabled"} 
                        onClick={handleSubmit(onSubmit)}
                      >
                       {!confirmationMsg ? "Register" : setLoading(true)}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            Modal
            size="l"
            show={showScannerModal}
            onHide={handleClose}
            // fullscreen={true}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {openQr && <QRScanner onSubmit={qrCodeSuccessCallback} />}

              <div className="card cardStyle"></div>
              {/* <div className='text-center my-5'><h4>{confirmationMsg}</h4></div> */}
            </Modal.Body>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default Registration;
