import React from "react";
import './preloader.css';

const Preloader = () => {
    return (
        <React.Fragment>
            <div className="preLoader_root">
                <div className="loader_book">
                    <div className="loader book">
                        <figure className="page"></figure>
                        <figure className="page"></figure>
                        <figure className="page"></figure>
                    </div>
                    <h1 className="reading_text">Reading</h1>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Preloader;