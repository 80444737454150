import QrScanner from "qr-scanner";
import React, { useEffect, useRef, useState } from "react";

const QRScanner = (props) => {
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState("");

  useEffect(() => {
    const video = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
   
        // props.qrCodeSuccessCallback()
        props.onSubmit(result.data)
        qrScanner.turnFlashOff()

 
        setScannedText(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    qrScanner.turnFlashOn()

    // setTimeout(() => {
    //   qrScanner.turnFlashOff()

    // }, 1000);
    console.log("start");

    return () => {
      //   console.log("qrScanner",qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
      qrScanner.turnFlashOff()
    };
  }, []);

  // const qrScanner = new QrScanner(videoElement, (result) =>
  //   console.log('decoded qr code:', result)
  // );

  return (
    <div className="row">
      <div className="videoWrapper col-12">
        <video className="qrVideo" ref={videoElementRef} />
      </div>
    </div>
  );
};

export default QRScanner;
