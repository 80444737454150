import React from "react";
import { Link } from "react-router-dom";
import footerImage from "../../template/images/footer_img.png";
import footerLogo from "../../template/images/powered_by.png";
import "./layout.css";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer_outer">
        <div className="container-lg">
          <div className="footer_inner">
            <p className="powered_by">Powered by</p>
            <img
              src={footerLogo}
              alt="Brand Logo"
              className="img-fluid footer_brand_logo"
            />
            <div className="row pb-4">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <p className="ftr_about_us footer-info">
                  We are a cutting-edge fintech organization, dedicated to
                  crafting technologically advanced financial services and
                  analytics products that cater to a wide spectrum of areas,
                  including investment advising, insurance, wealth management,
                  and financial education. Our dynamic team comprises skilled
                  professionals hailing from both the finance and technology
                  sectors, united by a shared zeal to design solutions that
                  offer unparalleled value to our clients in terms of both
                  return on investment and customer satisfaction.
                </p>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <div className="footer_nav">
                  <ul className="nav_ul">
                    {/* <li className="nav_li">
                                            <p>About Us</p>
                                        </li>
                                        <li className="nav_li">
                                            <p>Contact Us</p>
                                        </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="footer_address">
                  <div className="address_main">
                    <span>
                      <i className="fa-solid fa-location-dot"></i>
                    </span>
                    <div className="addres_info">
                      <p>
                        Finlabs India Private Limited Wework, G Block BKC,
                        Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra
                        400051
                      </p>
                    </div>
                  </div>
                  {/* <div className="mobile_main">
                    <span>
                      <i className="fa-solid fa-phone"></i>
                    </span>
                    <div className="mobile_info">
                      <p className="mobile_number">+919999999999</p>
                      <p className="mobile_number">+122222222</p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <img src={footerImage} className="img-fluid footer_image"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_lower"></div>
    </React.Fragment>
  );
};

export default Footer;
