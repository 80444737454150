const CustomDropDown = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? `rgba(var(--text-primary-color))` : "",
      backgroundColor: state.isSelected ? `rgba(rgba(var(--primary-color)),0.5)` : "",
      padding: 20,
      "&:hover": {},
    }),
    control: (base, state, styles, ) => ({
      ...base,
      ...base,
      ...base,
      ...styles,
      backgroundColor:"white",
      borderTop: "0",
      padding: 20,
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1",
      boxShadow: "none",
      borderRadius: "0",
      marginTop: "10px",
      borderColor: state.isSelected
        ? `#000`
        : `#000`,
      "&:hover": {
        ...base,
        ...base,
        borderTop: "0",
        padding: 20,
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1",
        boxShadow: "none",
        borderRadius: "0",
        borderColor: state.isSelected
          ? `#000`
          : `#000`,
      },
    }),
  
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
      minHeight : "45px !important",
      // marginBottom: "-15px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: "-5px",
      marginTop: "5px",
      
      textTransform: "uppercase !important",
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 9999,
        textTransform: "uppercase !important",
      };
    },
    placeholder: (provided, state) => ({
      ...provided,
      position: "relative",
      MaxWeight: "auto !important",
      fontFamily: "Roboto-Regular  !important",
      fontStyle: "normal  !important",
      fontWeight: "400  !important",
      lineHeight: "18px  !important",
      color: "#000",
      marginBottom: "10px  !important",
      top: state.hasValue || state.selectProps.inputValue ? -17 : -10,
      transition: "top 0.1s",
      fontSize:  state.hasValue || state.selectProps.inputValue ? "12px !important" : "15px !important",
      opacity: state.hasValue || state.selectProps.inputValue ? 1 : 1,
      paddingBottom:
        state.hasValue || state.selectProps.inputValue ? "2px" : "5px",
      marginLeft: "-5px",
    }),
  };
  
  export default CustomDropDown;
  