import React, { useEffect, useRef, useState } from "react";
import GPreloader from "./components/layout/GPreloader";

const GoogleTranslate = (props) => {
  const [loading, setLoading] = useState(true);
  const googleTranslateRfe = useRef(null);
  document.body.classList.add("Gpreloadercssbody");


  // Check translation load status

  useEffect(() => {
    let intervalid;
    const checkGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        clearInterval(intervalid);
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false,
            includedLanguages:
              localStorage.getItem("supportedWGLanguage") == null ||
              localStorage.getItem("supportedWGLanguage") == " "
                ? "en"
                : localStorage.getItem("supportedWTLanguage").toLowerCase(), // If you remove it, by default all google supported language will be included
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },

          googleTranslateRfe.current
        );
      }
    };
    // Delay removal of preloader until after translations are loaded
    const checkTranslationLoaded = () => {
      setTimeout(() => {
        setLoading(false);
        var element = document.getElementById("Gpreloaderrun");
        element.classList.remove("Gpreloader");
        document.body.classList.remove("Gpreloadercssbody");
      }, 1000);
    };
    intervalid = setInterval(checkGoogleTranslate, 100);
    const interval = setInterval(checkTranslationLoaded, 1000);
    return () => clearInterval(interval);
  }, [props]);
  function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
    document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
  }
  return (
    <>
      {loading ? (
        <GPreloader />
      ) : (
        <div style={{ opacity: "0" }} ref={googleTranslateRfe}></div>
      )}
    </>
  );
};

export default GoogleTranslate;
