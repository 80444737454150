// Gpreloader.js
import React from "react";
import "./GPreloader.css"; // Import your CSS for styling
import Loader_GIF from "./loader.svg";

const GPreloader = () => (
  <>
    <img className="Gpreloadercssimg" src={Loader_GIF} />
  </>
);

export default GPreloader;
