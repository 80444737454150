import React, { useEffect, useState } from "react";
import Loader from "../util/preloader/Preloader";
import Banner from "./../components/home/Banner";

const Landing = () => {
  const [loading, setLoading] = useState(true);
useEffect(() => {
  
  setTimeout(() => {
    setLoading(false)
}, 500);
}, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
     
          <Banner />
        </>
      )}
    </React.Fragment>
  );
};

export default Landing;
