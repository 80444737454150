import axios from '../AxiosConfig';

// interventions update
export function registerUser(id,data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post(`web/api/v1/lms-web/website/registerUser/${id}`,data,{
      headers: {
          'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
    });
}

export function getLanguage() {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`master/api/v1/lms-master/language/list`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function registerEXUser(id,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`web/api/v1/lms-web/website/registerUserIfExist/${id}`,data,{
     headers: {
         'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// api/v1/lms-web/website/eventList/${"NA"}/${"NA"}/${"NA"}

export function fetchEventlist(state,city,pincode,date,subject) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/website/eventList/${state}/${city}/${pincode}/${date}/${subject}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }



  export function getTheme() {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/admin/theme/getTheme`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
         // console.log('Error on Authentication');
       });
     });
  }
  export function fetchSingleEvent(eventid) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/website/getEvent/${eventid}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  export function fetchStateList() {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/website/getStateList`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  // api/v1/lms-web/website/getStateList
  // api/v1/lms-web/website/getCityList/we
  export function fetchCityList(State) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/website/getCityList/${State}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  export function fetchPincodeList(city) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/website/getPincodeList/${city}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  // /api/v1/lms-web/admin/session/getRegistrationQr/{sessionId}
  export function downloadQr(fileId) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`web/api/v1/lms-web/admin/session/getRegistrationQr/${fileId}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }
  // export function downloadQr(fileId,type,ename,date,time) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`web/api/v1/lms-web/admin/session/getRegistrationQr/${fileId}`, { responseType: 'blob' })
  //       .then(function (response) {
          
  //         let d = new Date();
  //         resolve(response.data);
  //         console.log("sssssssssssssssss",response.headers)
  //         console.log("header", response)
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', ename+"_"+date+"_"+time+"_"+type+".png") //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //       })
  //       .catch(function (error) {
  //         reject(error);
  
  //         // console.log('Error on Authentication');
  //       });
  //   });
  // }
  
  export function fetchpincode(eventid) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`master/api/v1/lms-master/venue/management/pincode/${eventid}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

export function fetchFeedbackQuestions(AuthStr) {
  return new Promise((resolve, reject) => {
    axios
      .get("admin/api/v1/lms-admin/feedback/getQuestionList",{ 'headers': { 'Authorization': AuthStr } })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getFeedback(userId, sessionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`admin/api/v1/lms-admin/feedback/getFeedback/${userId}/${sessionId}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// user/api/v1/lms-user/getOneTimeToken/eb636af6-197f-4fc4-889b-1b6497e6b7d9
export function getFeedToken(Tokenid) {
  return new Promise((resolve, reject) => {
    // headers.Authorization =  "Bearer "+token1;
    axios
      .get(`user/api/v1/lms-user/getOneTimeToken/${Tokenid}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function submitFeedback(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('admin/api/v1/lms-admin/feedback/submit',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

