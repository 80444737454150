import axios from "axios";

export function registerUser(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`web/api/v1/lms-web/website/registerUser/${id}`, data, {
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
  });
}

export function downloadQr(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`web/api/v1/lms-web/trainer/session/getOfflieAadhaarDataByQr`, data, {
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
        // console.log('Error on Authentication');
      });
  });
}