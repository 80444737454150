import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import SessionFeedback from './components/home/SessionFeedback';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
// import Registration from './components/layout/Registration';
import Registration from './components/loginAccount/Registration';
import Landing from './landing/landing';
import Video from './landing/video';

// import "./i18next";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Landing />} ></Route>
            <Route path="*" element={<ErrorPage />}></Route>
            <Route path="/Registration/:id" element={<Registration />} ></Route>
            <Route path="/Feedback/:Uid/:Sid/:Kid" element={<SessionFeedback />} ></Route>
            {/* <Route path="/video" element={<Video />} ></Route> */}

         
          </Routes>
          <Footer />
          
        </>
      </BrowserRouter>
    </div>
  );
}

function ErrorPage() {
  return (
    <React.Fragment>
      <h3>Error Page</h3>
    </React.Fragment>
  );
}

export default App;
