import { ENV } from '../../util/Env';
import { token } from './index';

const axios = require('axios').default;

axios.interceptors.request.use((config) => {
    const token1 = token();
    
   config.headers['Access-Control-Allow-Origin'] = "*";
//    res.header("Access-Control-Allow-Origin", "*");
    // if(token1 != null && token1 != '')
        config.headers.Authorization =  "";

    config.url = ENV.API_URL+config.url;
    // console.log("env",ENV.NAME)
    // console.log("env url",ENV.API_URL)
//    config.url ="http://localhost:10002/"+config.url;

    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if(response.status === 401 || response.status === 403) {
    // logout();
    }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        return Promise.reject(error.response);
    }
    return Promise.reject(error);
});

export default axios;