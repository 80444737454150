import React, { useEffect, useState } from "react";
import Loader from "../../util/preloader/Preloader";

import { useNavigate, useParams } from "react-router-dom";

import {
    faFaceFrown,
    faFaceMeh,
    faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { components } from "react-select";
import swal from "sweetalert";
import { ENV } from "../../util/Env";
// import { successMessages } from "../../../util/Messaging";

const SessionFeedback = () => {
  // const { courseId } = useParams();
  const { Uid } = useParams();
  const { Sid } = useParams();
  const { Kid } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [lesson, setLesson] = useState([]);
  //SHOW ERROR MSGS
  const [errorMsg, setErrorMsg] = useState("");
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);

  // const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  // const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);
  const navigate = useNavigate();
  const [authStr, setAuthStr] = useState("");
  const [stats, setStats] = useState();
  const [interventions, setInterventions] = useState();
  const [topPerformers, setTopPerformers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);
  const [userfeedbackSubmit, setUserfeedbackSubmit] = useState(false);

  const [options, setOptions] = useState([
    { id: 1, label: "Excellent", value: "Excellent", points: "10" },
    { id: 2, label: "Very Good", value: "Very Good", points: "7" },
    { id: 3, label: "Good", value: "Good", points: "5" },
    { id: 4, label: "Poor", value: "Poor", points: "3" },
  ]);

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
  } = useForm();

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const course = watch("course");

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type, "watchhhhh")
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const [uspTemData, setuspTemData] = useState([]);
  useEffect(() => {
    fetchAllFeedToken();
    fetchSingleEvent();
  }, [authStr]);

  const fetchSingleEvent = async () => {
    const url = ENV.API_URL + `web/api/v1/lms-web/website/getEvent/${Sid}`;
    const token = authStr;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: ``,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const results = await response.json();

      setuspTemData(results);
    } catch (error) {
      swal({
        text: "We are temporarily experiencing technical difficulties. Please try again after sometime ",
        icon: "error",
        button: "OK",
      }).then((value) => {
        if (value === true) {
          navigate(`/`);
        }
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchAllFeedToken = async () => {
    const url = ENV.API_URL + `user/api/v1/lms-user/getOneTimeToken/${Kid}`;
    const token = authStr;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: ``,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const results = await response.text();

      localStorage.setItem("user", results);
      fetchFeedback(results)

      setAuthStr(result);
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFeedback = async (x) => {
    const url = ENV.API_URL + `admin/api/v1/lms-admin/feedback/getFeedbackStatus/${Uid}/${Sid}`;


    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("result", result);
      if (result.avgScore == null) {
        fetchData(result);
        setUserfeedbackSubmit(false)
      } else {
        setUserfeedbackSubmit(true)
      }
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (x) => {
    const url = ENV.API_URL + `admin/api/v1/lms-admin/feedback/getQuestionList`;
    const token = x;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const selectOption = (event) => {
    console.log(event.id);

    perpareArray(event);
  };

  const perpareArray = (event) => {
    let points_selected = event.id.split("-")[1];
    let data_object = {
      questionId: event.name,
      answerPoint: points_selected,
      answerValue: event.value,
    };

    setResult((prevData) => [...prevData, data_object]);
    replaceObject(data_object);
  };

  const [newResult, setNewResult] = useState([]);

  const replaceObject = (newObj) => {
    // setameDatasss(prevData => {
    //   // Remove the object with the same id as newObj and add the new object
    //   return prevData.map(obj =>
    //     obj.questionId == newObj.questionId ? newObj : obj
    //   );
    // });

    setNewResult((prevData) => {
      // Remove the object with the same id as newObj
      const filteredData = prevData.filter(
        (obj) => obj.questionId !== newObj.questionId
      );
      // Add the new object
      return [...filteredData, newObj];
    });
  };
  const feedbackSubmission = async () => {
    const url = ENV.API_URL + `admin/api/v1/lms-admin/feedback/submit`;
    const token = authStr;
    const payload = {
      sessionId: Sid,
      userId: Uid,
      data: newResult,
    };

    setLoading(true);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      swal({
        text: "Thank You For Your Valuable Feedback",
        icon: "success",
        button: "OK",
      }).then((value) => {
        if (value === true) {
          localStorage.removeItem("user");
          navigate(`/`);
        }
      });
    } catch (error) {
      swal({
        text: !error.data
          ? "We are temporarily experiencing technical difficulties. Please try again after sometime "
          : error.data,
        icon: "error",
        button: "OK",
      }).then((value) => {
        if (value === true) {
        }
      });
    } finally {
      setLoading(false);
    }
  };
  // const feedbackSubmission = () => {
  //   setLoading(true)
  //   const headers = {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authStr}`,
  //     },
  //     body: JSON.stringify({
  //       sessionId: Sid,
  //       userId: Uid,
  //       data: newResult,
  //     }),
  //   };
  //   fetch(ENV.API_URL + `admin/api/v1/lms-admin/feedback/submit`, headers)
  //     .then((response) => response.json())
  //     .then(
  //       setLoading(false)

  //     );

  //   //   const resp = submitFeedback()
  //   //   resp.then((success) => {
  //   //     setLoading(false)
  //   //       // setSuccessMsg(successMessages.modal.feedback)
  //   //       setShowSuccessMsg(true)
  //   //       setTriggerSuccess(true)
  //   //       setTimeout(() => {

  //   //           navigate(`/Scheduleevents`)

  //   //       }, 3000);
  //   //   },
  //   // (error) => {
  //   //   setLoading(false);
  //   //   setErrorObj(error)
  //   //   setShowpopup(true);
  //   //   setPopup(true)
  //   // }
  //   // )
  // };

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* main page  */}
          <div className="usp_main Feedbackbg">

            <div className="container-lg ">
              <div className="row justify-content-center py-5">
                <div className="col-md-6 col-12 d-flex justify-content-center Feedbackbg5">
                  {!userfeedbackSubmit ? <div className="feedback-main">
                    <h2>Event Feedback : {uspTemData.eventTopicName}</h2>
                    <>
                      {/* <div className='ripple-background'>
  <div className='circle xxlarge shade1'></div>
  <div className='circle xlarge shade2'></div>
  <div className='circle large shade3'></div>
  <div className='circle mediun shade4'></div>
  <div className='circle small shade5'></div>
</div> */}
                      {data.map((item, index) => {
                        return (
                          <>
                            <div className="feedback-option-main-box">
                              <div
                                key={item.id}
                                className="feedback-option-box"
                              >
                                <p className="feedback-question-box">
                                  <span>Q{index + 1}.</span> {item.question}
                                </p>

                                <ul>
                                  {options.map((opt, idx) => {
                                    return (
                                      <>
                                        <li key={idx} className="option-list">
                                          <input
                                            type="radio"
                                            id={
                                              item.id +
                                              "_" +
                                              idx +
                                              "-" +
                                              opt.points
                                            }
                                            name={item.id}
                                            value={opt.label}
                                            onChange={(e) =>
                                              selectOption(
                                                e.target,
                                                data.length
                                              )
                                            }
                                          />

                                          <label
                                            for={
                                              item.id +
                                              "_" +
                                              idx +
                                              "-" +
                                              opt.points
                                            }
                                            className={"icon_label" + opt.label}
                                          >
                                            <div className="opt_icon">
                                              {opt.label == "Excellent" ? (
                                                <FontAwesomeIcon
                                                  className={
                                                    "icon_" + opt.label
                                                  }
                                                  icon={faFaceSmile}
                                                  size="xl"
                                                />
                                              ) : opt.label == "Very Good" ? (
                                                <FontAwesomeIcon
                                                  className={
                                                    "icon_" + opt.label
                                                  }
                                                  icon={faFaceSmile}
                                                  size="xl"
                                                />
                                              ) : opt.label == "Good" ? (
                                                <FontAwesomeIcon
                                                  className={
                                                    "icon_" + opt.label
                                                  }
                                                  icon={faFaceMeh}
                                                  size="xl"
                                                />
                                              ) : opt.label == "Poor" ? (
                                                <FontAwesomeIcon
                                                  className={
                                                    "icon_" + opt.label
                                                  }
                                                  icon={faFaceFrown}
                                                  size="xl"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            {opt.label}
                                          </label>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <div className="submit-btn">
                        <button
                          type="button"
                          className="w-25 text-center Reg_Button mt-3"
                          //   disabled={!isValid}
                          onClick={() => feedbackSubmission()}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  </div> : <div className="content">
                    <div className="wrapper-1">
                      <div className="wrapper-2">
                        <h1>Thank you for your feedback!</h1>
                        <p>We appreciate your input and want to let you know that your feedback has already been recorded</p>
                        <p> Have a great day.</p>
                        

                      </div>

                    </div>
                  </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default SessionFeedback;
