import React, { useState } from "react";
import Eventscard from './Eventscard';

const Eventslist = (props) => {
    const { EventType, upcomingDetails,
        todayDetails,
        todayallDetails,
        todayallbtn,
        tomorrowDetails,
        tomorrowallDetails,
        pastDetails,
        pastallDetails,
        pastallbtn,
        tomorrowallbtn,
        upcomingallDetails,
        upcomingallbtn } = props;
    

    const [isONtodayall, setIsONtodayall] = useState(false)
    const [isONtomorrowall, setIsONtomorrowall] = useState(false)
    const [isONupcomingall, setIsONupcomingall] = useState(false)
    const [isONpastall, setIsONPastall] = useState(false)



    return (
        <React.Fragment>


            <div className="usp_main">
                <div className="container-lg">

                    {todayallDetails ? <><div className="row justify-content-between align-items-center">
                        <div className="col"><p className="Events_day">{todayDetails.length <= 1 ? "Today Event" : "Today’s Events"}</p></div>
                        {!todayallbtn ? <div className="col d-flex justify-content-end"><button className="see-all-btn btn" onClick={() => setIsONtodayall(!isONtodayall)}>See All</button></div> : ""}

                    </div>
                        <div className="row">
                            {isONtodayall ? <> {todayDetails.map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</> : <> {todayDetails.slice(0, 3).map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</>}



                        </div></> : ""}


                    {tomorrowallDetails ? <><div className="row justify-content-between align-items-center">
                        <div className="col"><p className="Events_day">{tomorrowDetails.length <= 1 ? "Tomorrow Event" : "Tomorrow’s Events"}</p></div>
                        {!tomorrowallbtn ? <div className="col d-flex justify-content-end" onClick={() => setIsONtomorrowall(!isONtomorrowall)}><button className="see-all-btn btn">See All</button></div> : ""}

                    </div>
                        <div className="row">

                            {isONtomorrowall ? <> {tomorrowDetails.map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</> : <> {tomorrowDetails.slice(0, 3).map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</>}


                        </div></> : ""}

                    {upcomingallDetails ? <><div className="row justify-content-between align-items-center">
                        <div className="col"><p className="Events_day">Upcoming Events</p></div>
                        {!upcomingallbtn ? <div className="col d-flex justify-content-end"><button className="see-all-btn btn" onClick={() => setIsONupcomingall(!isONupcomingall)}>See All</button></div> : ""}

                    </div>
                        <div className="row">
                            {isONupcomingall ? <> {upcomingDetails.map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</> : <> {upcomingDetails.slice(0, 3).map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} />
                                </>
                            ))}</>}



                        </div></> : ""}

                    {pastallDetails ? <><div className="row justify-content-between align-items-center">
                        <div className="col"><p className="Events_day">{pastDetails.length <= 1 ? "Past Event" : "Past Events"}</p></div>
                        {!pastallbtn ? <div className="col d-flex justify-content-end"><button className="see-all-btn btn" onClick={() => setIsONPastall(!isONpastall)}>See All</button></div> : ""}

                    </div>
                        <div className="row">
                            {isONpastall ? <> {pastDetails.map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} page="past" />
                                </>
                            ))}</> : <> {pastDetails.slice(0, 3).map((usp, index) => (
                                <>

                                    <Eventscard storeData={usp} page="past" />
                                </>
                            ))}</>}



                        </div></> : ""}
                </div>

            </div>
        </React.Fragment>
    )
}

export default Eventslist;