export const ValidationPattern = {
    name: /^[a-zA-Z ]+$/,

    birthPlace: /^[a-zA-Z0-9 ]+$/,

    number: /^[0-9 ]+$/,
    email:
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    
    mobile:/^(?!0{1})(?:(?:\+0{0,2})91(\s*[-]\s*)?|[0]?)?[123456789]\d{9}$/,
   
    pin: /^[0-9]{6}$/,
//     mobileri:
//       /^(?:(?:\+|0{0,3})91(\s*|[\-])?|[1-9]?)?([1-9]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/,
//     mobileTEM: /^(?!\+\d{14})(\+\d{0})?\d{1,13}$/,
//     nrimobile: /^(?!\+91\d{10})(?!([\d])\1{9})(\+\d{1,3})?\d{7,10}$/,
//     nri: /^(\+\d{7,14})$/,
//     Address: /^[a-zA-Z0-9\s,.'()~!@#$%-/\\,.\s&*()_+-={}\[\]|?;: ]{3,60}$/,
//     aNumber: /^(?!0{4})[a-zA-Z0-9]+$/,
//     pannumber:
//       /^[a-zA-Z]{3}[pP]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
//     accountNumber: /^\d(?!0{6})[0-9 ]{8,29}$/,
//     // accountNumber1:/^(?!0{6})[0-9 ]{8,29}$/,
//     accountNumber1: /^(?!0{6})[a-zA-Z0-9]{9,30}$/,
//     amoutn: /^[0-9 ]+$/,
//     amoutnminCharges: /^\d{1,5}(?:\.\d{1,2})?$/,
//   GSTCharges: /^\d{1,2}(?:\.\d{1,2})?$/,
//     l500amoutn:/^(?!.-+E)(?!.*[+.\-])(?=.{1,15}$)(?:500|[5-9]\d{2,}|[1-9]\d{3,})$/,
//     // l250amoutn:/^(?!.-+E)(?!.*[+.\-])(?=.{1,30}$)(?:1000|[1-9]\d{3,}|[1-9]\d{3,})$/,
//     l250amoutn:/^(?!.-+E)(?!.*[+.\-])(?=.{1,15}$)(?:250|[2-9]\d{2,}|[1-9]\d{3,})$/,
//     l1000amoutn:/^(?!.-+E)(?!.*[+.\-])(?=.{1,15}$)(?:1000|[1-9]\d{3,}|[1-9]\d{3,})$/,
//     l50000amoutn:/^([5-9]\d{2}|[0-9]\d{3}|[0-4]\d{4}|50000)$/,  
//     role: /^[0-9a-zA-Z ]+$/,
//     passport: /^[A-Za-z][0-9]{7}$/,
//     Voter: /^[a-zA-Z0-9]{7,12}$/,
//     aadhaar: /^\d{4}$/,
//     aadhaar1: /^\d{1}$/,
//     aadhaar12: /^\d{17}$/,
//     otp: /^[0-9]{1,6}$/,
//     pran: /^[0-9]{10,12}$/,
//     alphanumeric: /^[a-zA-Z0-9]*$/,
//     ociCardNumber: /^[A-Za-z][0-9]{7}$/,
//     ifscCode: /^[A-Za-z]{4}0[A-Z0-9]{6}$/,
//     bankBranch: /^[A-Za-z]{4}$/,
//     //aNumber: /^(?!0{4})[0-9]+$/,
//     //tax: /^[a-zA-Z0-9]*$/,
//     parn: /^\d{4}$/,
//     ifsc: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/g,
//     refNo: /^\S+$/,
//     upi: /^[A-Za-z][0-9]{7}$/,
//     contributionUPI: /^\d{6,15}$/,
//     employeeID:/^[a-zA-Z0-9]*$/,
  };