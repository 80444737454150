import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import CustomDropDown from './CustomDropDown';




const SelectElement = (props) => {

    const {
        t,
        register,
        errors,
        setValue,
        trigger,
        control,
        getValues,

        divClassName,
        readOnly=false,
        previewFlag=false,
        onSelect=()=>{},
        handleInputChange=()=>{},
        registerName,
        mandatory=false,
        labelName="",
        label,
        errorLabel="",
        id,
        accessibilityLabel,
        useForm,
        options=[],
        isMulti=false,
        
    } = props

    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        
        return (
            <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
              {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
              child && child.type !== Placeholder ? child : null
            )}
          </ValueContainer>
        );
    };

    

    

    

    useEffect(()=>{
        // setValue("ifscCode",{label:"label",value:"value"})
    },[])

    

    return(
        <div className={"form-floating formgroup "+divClassName}>
            
            <Controller
                control={control}
                name={registerName}
                rules={{
                    required: {
                        value: mandatory,
                        message: errorLabel,
                    },
                }}
                render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            components={{
                                ValueContainer: CustomValueContainer,
                            }}
                            isMulti={isMulti}
                            menuPlacement="auto"
                            styles={CustomDropDown}
                            isDisabled={readOnly}
                            className={
                                previewFlag
                                ? "Selectontrol previewStyle"
                                : errors?.[registerName]
                                ? "Selectontrol errorsborder"
                                : "Selectontrol"
                            }
                            menuColor="red"
                            value={value}
                            placeholder={
                                <label
                                    htmlFor={registerName}
                                    className="label-heading"
                                >
                                    { labelName }
                                    { mandatory ? <span className="errormark"> *</span> : null }
                                </label>
                            }
                            
                            
                            options={options}
                            {...register(registerName,{
                                
                            })}
                            onChange={ (value) => {
                                
                                onSelect(value)
                                onChange(value)
                                setValue(name,value)
                            }}
                            onInputChange={(value)=>handleInputChange(value)}
                        />
                    )}
                /> 
            
            {!(errors?.[registerName]?.ref?.value) && (
                <small className="textdanger">
                    {errors?.[registerName]?.message}
                </small>
            )}
        </div>
    )
}

export default SelectElement