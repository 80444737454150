import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const InputElement = (props) => {

    const {
        t,
        register,
        errors,
        setValue,
        trigger,
        control,
        getValues,
        divClassName,
        readOnly=false,
        maxLength=null,
        previewFlag=false,
        type="text",
        defaultValue=null,
        onChange=()=>{},
        onBlur=()=>{},
        registerName,
        mandatory=false,
        labelName="",
        label,
        errorLabel="",
        id,
        hidden,
        onPaste,
        onCopy,
        accessibilityLabel,
        useForm,
        pattern={},
        secondaryErrorLabel=false,
        secondarySuccessMsg=false,
        ref=null,        
    } = props
    // console.log("registerName  ",registerName, pattern, errors)
    return(
        <div className={"form-floating formgroup "+ divClassName}>
            <input
              onPaste={(e) => {
                e.preventDefault();
                return onPaste;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return  onCopy;
              }}
                // readOnly={readOnly}
                disabled={readOnly}
                ref={ref}
                hidden={hidden}
                type={type}
                maxLength={maxLength}
                // value={getValues(registerName)}
                className={
                previewFlag
                    ? "form-control formcontrol_ipa previewStyle"
                    : errors?.[registerName]
                    ? "form-control  formcontrol_ipa errorsborder"
                    : "form-control  formcontrol_ipa"
                }
                id={registerName}
                {...register(registerName, {
                    required: mandatory ? errorLabel : false,
                    pattern: pattern,
                    onChange: (e) => onChange(e),
                    onBlur: (e) => onChange(e),
                    
                })}
                placeholder={registerName}
                onKeyUp={() => {
                    trigger(registerName);
                }}
            />
            <label htmlFor={registerName} className="label-heading input_ipa">
                {labelName} 
                { mandatory ? <span className="errormark"> *</span> : null }
            </label>
            {errors?.[registerName]  && (
                <small className="textdanger">
                {errors?.[registerName]?.message}
                </small>
            )}

            {secondaryErrorLabel ? (
                <small className="textdanger ">
                <i
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                ></i>{" "}
                Account details not matched
                </small>
            ) : null }

            {secondarySuccessMsg ? (
                    <small className="textapp_color_success ">
                    <FontAwesomeIcon icon={faCheckCircle} className="tick-icon" />    
                    </small>
                )
             
            : null }
        </div>
    )
}

export default InputElement

   